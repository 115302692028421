import React, { useState, useEffect } from "react"

import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { useMutation } from "@apollo/client"
import Seo from "components/seo"
import { parseCookies } from 'nookies'

import Layout from "components/layout"
import { Button } from "components/anti/buttons/buttons"
import { Input } from "components/anti/forms/forms"

import pakdok from "assets/img/logomark_pakdok.svg"
import da from "assets/img/logomark_flat.svg"
import circle from "assets/img/roll-animate.svg"

import { registerPakDokQuery } from "components/utils/streamworx"



const Register = () => {
    const [tnc, setTnc] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const cookies = parseCookies()

    const [registerPakDok] = useMutation(registerPakDokQuery, { context: { clientName: 'streamworx' } })


    const initialRegisterValues = {
        username: "",
        password: "",
    }

    const YupRegisterValidation = Yup.object({
        username: Yup.string().required("Masukkan Username anda"),
        password: Yup.string()
            .required('Password tidak boleh kosong')
            .min(8, 'Password terlalu pendek'),
    })

    //Handle from formik
    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        const phoneNumber = cookies.phoneNumber
        const submitValues = {
            ...values, phoneNumber
        }
        setErrMsg("")
        registerPakDok({
            variables: { ...submitValues }
        }).then(res => {
            setSubmitting(false)
        }).catch(err => {
            console.log("err", err)
            setErrMsg("Maaf, terjadi kesalahan")
            setSubmitting(false)
        })
    }

    // useEffect(() => {
    //     const phoneNumber = cookies.phoneNumber
    //     if (!phoneNumber) {
    //         // navigate("/login")
    //         window.location.replace("/")
    //     }
    // }, [])

    return (
        <Layout>
            <Seo title="PakDok" />
            <section className="py-main sc-register-register">
                <div className="container mw-sm">
                    <Button
                        variant="link"
                        className="btn-back-help ai-arrow-from-left mb-3"
                        link={"/"}
                    >
                        Kembali
                    </Button>
                    <h2>Masuk dengan PakDok</h2>
                    <p>Nomor HP Kamu telah terdaftar dalam aplikasi PakDok. Masukkan e-mail dan password PakDok untuk melanjutkan</p>
                    <div className="d-flex align-items-center my-3">
                        <img src={pakdok} alt="pakdok" className="img-fluid h-50px" />
                        <i className="ais ai-arrow-right mx-4" />
                        <img src={da} alt="pakdok" className="img-fluid h-50px mr-3" />
                    </div>
                    <Formik
                        validationSchema={YupRegisterValidation}
                        initialValues={initialRegisterValues}
                        onSubmit={handleSubmit}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            touched,
                            isSubmitting,
                            errors,
                            values,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <Input
                                            floatingLabel
                                            variant={"underline"}
                                            label={"Username"}
                                            as="input"
                                            id="username"
                                            name="username"
                                            // type="email"
                                            onChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <Input
                                            floatingLabel
                                            variant={"underline"}
                                            label={"Password"}
                                            as="input"
                                            type="password"
                                            id="password"
                                            name="password"
                                            onChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="form-group col-12 d-flex justify-content-start align-items-center mt-5">
                                        <Field type="checkbox" name="tnc" onClick={setTnc(values.tnc)} />
                                        <p className="mb-0 ml-4">Saya telah membaca dan setuju terhadap <span className="font-weight-bold">Syarat & Ketentuan</span> yang berlaku</p>
                                    </div>
                                    <div className="form-group col-12">
                                        <Button variant="primary" className="btn-block mt-4" type="submit" disabled={!tnc || isSubmitting}>
                                            {isSubmitting ? (
                                                <img src={circle} className="img-fluid loading" />
                                            ) : (
                                                "Lanjut"
                                            )}
                                        </Button>
                                        <div className="invalid-feedback">{errMsg}</div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
        </Layout>
    )
}

export default Register